import { dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://619aef459a380c64a42933e72b8be320@o4505878437298176.ingest.sentry.io/4505878441951232',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [Sentry.replayIntegration()],
	enabled: !dev
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
